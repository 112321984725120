/* SortComponent.module.css */
.sortContainer {
    display: flex;
    align-items: center;  /* Align label and select vertically */
    background-color: #3d9095;
    padding: 10px 0 10px 10px;
    border-radius: 8px;
    margin-top: 10px;
    width: 300px;
}

label {
    margin-right: 10px;
    color: rgb(255, 255, 255);
}

select {
    padding: 5px;
    border-radius: 5px;
    border: none;
}
