.content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;  
    background-color: #282c34; 
   
}

.left-side-content {
    flex: 1;
    max-width: 325px; 
    border-radius: 8px;
    padding: 10px 0px 10px 10px;
    
}

.right-side-content {
    flex: 2;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.fixed-icon {
    position: fixed;
    bottom: 20px;
    left: 10px;
    width: 40px;
    height: 40px;
    z-index: 1000; /* Ensures the icon is on top */
}

.fixed-icon img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
    filter: invert();
}

.fixed-icon:hover img {
    opacity: 0.5;
}
